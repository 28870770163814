import type { SportType } from 'types/scoreboardData';

const sportTypeList = {
  all: 'universal',
  badminton: 'badminton',
  baseball: 'baseball',
  basketball: 'basketball',
  billiard: 'billiard',
  cricket: 'cricket',
  football: 'football',
  futsal: 'futsal',
  golf: 'golf',
  handball: 'handball',
  jokgu: 'jokgu',
  pickleball: 'pickleball',
  sepakTakraw: 'sepakTakraw',
  soccer: 'soccer',
  squash: 'universal',
  tableTennis: 'tableTennis',
  taekwondo: 'taekwondo',
  tennis: 'tennis',
  volleyball: 'volleyball',
  universal: 'universal',
  athletics: 'universal',
  esports: 'universal',
  hockey: 'universal',
  iceHockey: 'universal',
  bodybuilding: 'universal',
  climbing: 'universal',
  rugby: 'rugby',
};

export const matchThumbnailPicker = (sportType: SportType) => {
  return `/img/public_matchList/${sportTypeList[sportType]}_Video_Thumbnail.webp`;
};

export const eventThumbnailPicker = (sportType: SportType) => {
  return `/img/public_eventList/${sportTypeList[sportType]}_Event_Thumbnail.webp`;
};
