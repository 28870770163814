import useTranslation from 'next-translate/useTranslation';

import type { ChipProps} from '@mui/material';
import { Chip, Typography } from '@mui/material';

import useCheckDevice from 'hooks/useCheckDevice';

import type { EventStatus } from 'types/event';
import type { StatusType } from 'types/matchData';

interface StatusChipProps extends Omit<ChipProps, 'color' | 'label'> {
  isChipOnCard?: boolean;
  status: StatusType | EventStatus | 'ad';
}

const StatusChip = ({ isChipOnCard, status, sx, ...rest }: StatusChipProps) => {
  const { t } = useTranslation('scoreboard');
  const { isMobile } = useCheckDevice();

  return (
    <Chip
      color={
        isChipOnCard
          ? undefined
          : status === 'register'
            ? 'success'
            : status === 'schedule' || status === 'scheduled'
              ? 'secondary'
              : status === 'live' || status === 'progress'
                ? 'primary'
                : 'warning'
      }
      label={
        <Typography
          className="status-chip-label"
          variant={isChipOnCard && isMobile ? 'subtitle1Bold' : 'chip'}
        >
          {t(`statusList.${status}`)}
        </Typography>
      }
      sx={{
        boxSizing: 'border-box',
        '& > span': { padding: 0 },
        ...(isChipOnCard
          ? {
              backgroundColor: status === 'ad' ? 'rgba(0,0,0,0.8)' : '#BF0C0E',
              color: 'white',
              padding: '2px 8px',
              height: isMobile ? '24px' : '18px',
              width: isMobile ? '66px' : '50px',
            }
          : {
              height: '22px',
              padding: '2px 16px',
            }),
        ...sx,
      }}
      {...rest}
    />
  );
};

export default StatusChip;
