import { useMemo } from 'react';
import ReactPlayer from 'react-player';

import { useQuery } from '@tanstack/react-query';

import useTanstackQuery from './useTanstackQuery';

const useValidateVideo = (url: string) => {
  const { setTimeQueryOptions } = useTanstackQuery();

  const {
    isPlayableReactPlayer,
    isFacebookLink,
    isYoutubeLink,
    youtubeId,
    convertedUrl,
  } = useMemo(() => {
    if (url === '' || url === null)
      return {
        isPlayableReactPlayer: false,
        isFacebookLink: false,
        isYoutubeLink: false,
        youtubeId: null,
        convertedUrl: url,
      };

    // ! 페이스북의 경우, 일반 영상과 삭제된 영상 구분방법이 아직 미비
    // 리액트 플레이어에서는 둘다 재생가능, noembed에서는 둘다 재생불가능으로 나와서 구분불가능 -> 따라서 우선 두 가지 모두 재생가능으로 판단
    const isFacebookLink =
      /^(https?:\/\/)?(www\.)?(facebook\.com|fb\.watch)(\/|\/.*)?$/.test(url);

    const isYoutubeLink =
      /^(http(s)?:\/\/)?((w){3}.)?(m\.)?youtu(be|.be)?(\.com)?\/.+/.test(url);

    // 유튜브 링크에서 id를 추출
    const getYoutubeId = (url: string): string | null => {
      const regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|live\/|watch\?v=|&v=|shorts\/)([^#&?]*).*/;
      const match = url.match(regExp);
      return match && match[2].length === 11 ? match[2] : null;
    };

    // ! 페이스북도 시작시간이 적용되는 링크도 있으나 기준이 명확치 않아서 제외
    // 링크 쿼리파라미터에서 t=123s 형태의 timestamp 추출, 없으면 빈 문자열 반환
    const getYoutubeStartTime = (url: string): string => {
      const queryString = url.split('?')[1];
      if (!queryString) return '';

      const regex = new RegExp(`[?&]t=([^&#]*)`, 'i');
      const match = queryString.match(regex);

      return match ? `&t=${decodeURIComponent(match[1])}` : '';
    };

    const youtubeId = isYoutubeLink ? getYoutubeId(url) : null;

    // 유튜브 링크면 https://www.youtube.com/watch?v=dQw4w9WgXcQ 와 같은 통일된 형태로 변환, 아니면 그냥 반환
    const convertUrl = (url: string) => {
      if (!isYoutubeLink || !youtubeId) return url;

      return `https://www.youtube.com/watch?v=${youtubeId}${getYoutubeStartTime(url)}`;
    };

    const convertedUrl = convertUrl(url);

    // 1차 리액트 플레이어로 검증
    const checkReactPlayer = (url: string) => {
      if (url === '' || url === null) return false;
      else return ReactPlayer.canPlay(convertedUrl);
    };

    return {
      isPlayableReactPlayer: checkReactPlayer(url),
      isFacebookLink,
      isYoutubeLink,
      youtubeId,
      convertedUrl,
    };
  }, [url]);

  // 1차에서 재생가능 & 유튜브 링크 -> 2차 유튜브 api로 검증
  const { data: youtubeVideoData, isLoading: isYoutubeLoading } = useQuery({
    queryKey: ['validateVideoCheck', 'youtube', convertedUrl],
    queryFn: async () =>
      await (
        await fetch(
          `https://www.googleapis.com/youtube/v3/videos?id=${youtubeId}&part=status&key=${process.env.NEXT_PUBLIC_FIREBASE_API_KEY}`,
        )
      )
        .json()
        .then((data) => data.items),
    enabled: isPlayableReactPlayer && isYoutubeLink,
    ...setTimeQueryOptions(60),
  });

  // 1차에서 재생가능 -> 3차 noembed로 검증
  const { data: noembedVideoData, isLoading: isNoembedLoading } = useQuery({
    queryKey: ['validateVideoCheck', 'noembed', convertedUrl],
    queryFn: async () =>
      await (await fetch(`https://noembed.com/embed?url=${url}`)).json(),
    enabled: isPlayableReactPlayer,
    ...setTimeQueryOptions(60),
  });

  // 이때 noembedVideoData의 error 프로퍼티가 없으면 재생가능
  const isPlayableNoembed = !noembedVideoData?.error;

  // ! 유튜브 영상의 경우, 유튜브 api를 통해 200 / 401 / 403 & 404를 구분
  // ! noEmbed api 추가 호출을 통해 403 / 404 구분
  const youtubeResponseCode = useMemo(() => {
    const noEmbedErrorCode = Number(noembedVideoData?.error?.split(' ')[0]);

    if (isNoembedLoading && isYoutubeLoading) return 200;
    if (youtubeVideoData?.length > 0) {
      // 재생 가능한 영상 200
      if (youtubeVideoData[0].status.embeddable) return 200;
      // 공유 비허용 영상 401
      return 401;
      // 비공개 영상 403, 삭제된 영상 404
    } else if (noEmbedErrorCode === 403 || noEmbedErrorCode === 404)
      return noEmbedErrorCode;
    else return 400;
  }, [
    isNoembedLoading,
    isYoutubeLoading,
    noembedVideoData?.error,
    youtubeVideoData,
  ]);

  // 링크가 없는 영상 400
  if (url === '' || url === null)
    return {
      link: url,
      canPlay: false,
      responseCode: 400,
      isVideoLoading: false,
      videoData: {
        youtube: undefined,
        noembed: undefined,
      },
    };

  // ReactPlayer 로 재생 불가능한 영상 - 링크가 있으나 잘못된 요청 400
  if (!isPlayableReactPlayer)
    return {
      link: convertedUrl,
      canPlay: false,
      responseCode: 400,
      isVideoLoading: false,
      videoData: {
        youtube: undefined,
        noembed: undefined,
      },
    };

  // 유튜브 링크
  if (isYoutubeLink)
    return {
      link: convertedUrl,
      canPlay: youtubeResponseCode === 200,
      responseCode: youtubeResponseCode,
      isVideoLoading: isYoutubeLoading || isNoembedLoading,
      videoData: {
        youtube: youtubeVideoData,
        noembed: noembedVideoData,
      },
    };

  // 페이스북 링크, noEmbed 과정 생략, 리액트 플레이어만으로 판단
  if (isFacebookLink)
    return {
      link: convertedUrl,
      canPlay: isPlayableReactPlayer,
      responseCode: isPlayableReactPlayer ? 200 : 400,
      isVideoLoading: false,
      videoData: {
        youtube: undefined,
        noembed: noembedVideoData,
      },
    };

  // 유튜브 | 페이스북이 아닌 링크 재생되거나 200 or 삭제되었거나 404
  return {
    link: convertedUrl,
    canPlay: isPlayableNoembed,
    responseCode: isPlayableNoembed ? 200 : 400,
    isVideoLoading: isNoembedLoading,
    videoData: {
      youtube: undefined,
      noembed: noembedVideoData,
    },
  };
};

export default useValidateVideo;
