import { useCallback, useMemo, useState } from 'react';

const useAutoPlay = (mainLink: string | null, isPlayable: boolean) => {
  const [isAutoPlay, setIsAutoPlay] = useState(false);

  const playVideo = useCallback(() => setIsAutoPlay(true), []);
  const stopVideo = useCallback(() => setIsAutoPlay(false), []);

  const isAutoPlayable = useMemo(() => {
    return mainLink ? isPlayable : false;
  }, [mainLink, isPlayable]);

  const mobileVideoControl = async (
    entries: IntersectionObserverEntry[],
    matchCardIndex: number,
    focusVideoIndex: number,
    addPlayList: (idx: number) => void,
    deletePlayList: (idx: number) => void,
  ) => {
    const [entry] = entries;
    if (entry.isIntersecting && isAutoPlayable) addPlayList(matchCardIndex);
    else {
      deletePlayList(matchCardIndex);
    }

    if (
      focusVideoIndex === matchCardIndex &&
      entry.isIntersecting &&
      isAutoPlayable
    )
      setIsAutoPlay(true);
    else setIsAutoPlay(false);
  };

  return {
    isAutoPlay,
    mobileVideoControl,
    playVideo,
    stopVideo,
  };
};

export default useAutoPlay;
