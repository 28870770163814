import type { MouseEvent } from 'react';
import { useRef, useState } from 'react';

import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import CopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/DeleteOutlineRounded';
import EditIcon from '@mui/icons-material/Edit';
import LayerIcon from '@mui/icons-material/LayersOutlined';
import MoreIcon from '@mui/icons-material/MoreVert';
import ShareIcon from '@mui/icons-material/Share';
import type { SxProps } from '@mui/material';
import {
  Box,
  CircularProgress,
  Drawer,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';

import { updateRDBToServer } from 'api/RDB/util';
import { deleteStoreMatchData } from 'api/firestore';
import useScoreboardApi from 'api/hooks/useScoreboardApi';
import { getServerMatchData } from 'api/scoreboard';

import CustomCircularProgress from 'components/atoms/CustomCircularProgress';
import { Lock as LockIcon } from 'components/atoms/Icons';

import useActiveAuth from 'hooks/useActiveAuth';
import useCheckDevice from 'hooks/useCheckDevice';
import useClipboardCopy from 'hooks/useClipboardCopy';
import useLoading from 'hooks/useLoading';
import useTanstackQuery from 'hooks/useTanstackQuery';

import type {
  DisclosureType,
  MatchCopyDataType,
  MatchPlayer,
  ServerMatchDetailDataType,
  ServerMatchListDataType,
} from 'types/matchData';

const DisclosureDialog = dynamic(
  () => import('components/organisms/DisclosureDIalog'),
  { loading: () => <CustomCircularProgress /> },
);
const DeleteDialog = dynamic(
  () => import('components/molecules/DeleteDialog'),
  { loading: () => <CustomCircularProgress /> },
);
const MatchEditPopup = dynamic(
  () => import('components/organisms/MatchEditPopup'),
  { loading: () => <CustomCircularProgress /> },
);
const MatchGeneratePopup = dynamic(
  () => import('components/organisms/MatchGeneratePopup'),
  { loading: () => <CustomCircularProgress /> },
);

const MatchCardMenuButton = ({
  disclosure,
  isMyPage,
  matchId,
  matchListData,
  thumbnailImg,
}: {
  disclosure: DisclosureType;
  isMyPage: boolean;
  matchId: string;
  matchListData: ServerMatchListDataType;
  thumbnailImg: string;
}) => {
  const { deleteMatch } = useScoreboardApi();
  const { token, user } = useActiveAuth();
  const { shareUrl } = useClipboardCopy();
  const router = useRouter();
  const { t } = useTranslation('scoreboard');
  const { isNotPc } = useCheckDevice();
  const { resetQueries } = useTanstackQuery();

  const {
    isLoading: isEditPopupLoading,
    loadingStart: editPopupLoadingStart,
    loadingEnd: editPopupLoadingEnd,
  } = useLoading();

  const {
    isLoading: isCopyPopupLoading,
    loadingStart: copyPopupLoadingStart,
    loadingEnd: copyPopupLoadingEnd,
  } = useLoading();

  const {
    isLoading: isWidgetPageLoading,
    loadingStart: widgetPageLoadingStart,
    loadingEnd: widgetPageLoadingEnd,
  } = useLoading();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [isCopyPopupOpen, setIsCopyPopupOpen] = useState(false);
  const [isPublicPopupOpen, setIsPublicPopupOpen] = useState(false);
  const [isShowMatchDeleteDialog, setIsShowMatchDeleteDialog] = useState(false);

  const matchDetailDataRef = useRef<ServerMatchDetailDataType>();
  const matchCopyDataRef = useRef<MatchCopyDataType>();

  const handleClickEditBtn = async () => {
    try {
      editPopupLoadingStart();
      const updatedMatchData = await updateRDBToServer(
        token,
        matchId,
        user?.id,
      );

      if (updatedMatchData) {
        matchDetailDataRef.current = updatedMatchData;
      } else {
        const gottenMatchData = await getServerMatchData(matchId);
        matchDetailDataRef.current = gottenMatchData;
      }
      setIsEditPopupOpen(true);
    } catch (error) {
      console.error('Update RDB to server error : ', error);
    } finally {
      editPopupLoadingEnd();
      setAnchorEl(null);
    }
  };

  const handleClickCopyBtn = async () => {
    try {
      copyPopupLoadingStart();

      const matchData = await getServerMatchData(matchId);

      const awayPlayers: MatchPlayer[] = [];
      const homePlayers: MatchPlayer[] = [];
      const playerDefaultData = {
        goalCount: 0,
        warningCount: 0,
        isOut: null,
        fouls: null,
      };

      matchData.players.forEach((player) => {
        const playerInfo = {
          name: player.name,
          number: player.number ?? null,
          position: player.position ?? '',
          type: player.type,
          id: player.id,
          teamType: player.teamType,
          index: player.index,
          teamMemberId: player.teamMemberId ?? undefined,
        };

        if (player.teamType === 'home') {
          homePlayers.push({
            ...playerDefaultData,
            ...playerInfo,
          });
        } else {
          awayPlayers.push({
            ...playerDefaultData,
            ...playerInfo,
          });
        }
      });

      matchCopyDataRef.current = {
        awayPlayers,
        awayTeamId: matchData.awayTeamId,
        connectedAwayTeamId: matchData.connectedAwayTeamId,
        awayTeamImg: matchData.awayTeamImg,
        awayTeamName: matchData.awayTeamName,
        description: matchData.description,
        detailAddress: matchData.detailAddress,
        hashtags: matchData.hashtags,
        homePlayers,
        homeTeamId: matchData.homeTeamId,
        connectedHomeTeamId: matchData.connectedHomeTeamId,
        homeTeamImg: matchData.homeTeamImg,
        homeTeamName: matchData.homeTeamName,
        league: matchData.league,
        leagueArea: matchData.leagueArea,
        leagueType: matchData.leagueType,
        originalMatchUid: matchData.matchUid,
        placeId: matchData.placeId,
        sportType: matchData.sportType,
        matchNumber: matchData.matchNumber,
        matchType: matchData.matchType,
        matchDate: matchData.date,
        matchStartTime: matchData.startTime,
        title: t('copyOfTitle', { title: matchData.title }),
        numberInOneTeam: matchData.numberInOneTeam ?? 0,
      };
      setIsCopyPopupOpen(true);
    } catch (error) {
      console.error('Copy match data error : ', error);
    } finally {
      copyPopupLoadingEnd();
      setAnchorEl(null);
    }
  };

  const handleClickShareBtn = () => {
    shareUrl(
      `${process.env.NEXT_PUBLIC_HOST_URL}/${router.locale}/match/${matchId}`,
    );
    setAnchorEl(null);
  };

  const menuList: {
    id: 'edit' | 'copy' | 'disclosure' | 'widget' | 'share' | 'delete';
    classForGTM?: string;
    isMyMenu: boolean;
    icon: JSX.Element;
    customSx?: SxProps;
    onClick:
      | (() => void)
      | (() => Promise<void>)
      | ((e: MouseEvent<HTMLElement>) => void);
  }[] = [
    {
      id: 'edit',
      classForGTM: 'gtm-match-edit-btn',
      isMyMenu: true,
      icon: isEditPopupLoading ? (
        <CircularProgress size="24px" />
      ) : (
        <EditIcon fontSize="small" />
      ),
      onClick: handleClickEditBtn,
    },
    {
      id: 'copy',
      classForGTM: 'gtm-match-copy-btn',
      isMyMenu: true,
      icon: isCopyPopupLoading ? (
        <CircularProgress size="24px" />
      ) : (
        <CopyIcon fontSize="small" />
      ),
      onClick: handleClickCopyBtn,
    },
    {
      id: 'disclosure',
      classForGTM: 'gtm-match-disclosure-btn',
      isMyMenu: true,
      icon: <LockIcon fontSize="small" />,
      onClick: () => {
        setIsPublicPopupOpen(true);
        setAnchorEl(null);
      },
    },
    {
      id: 'widget',
      classForGTM: 'gtm-control-btn',
      isMyMenu: true,
      icon: isWidgetPageLoading ? (
        <CircularProgress size="24px" />
      ) : (
        <LayerIcon fontSize="small" />
      ),
      onClick: async () => {
        widgetPageLoadingStart();
        await router.push(`/control/${matchId}`);
        widgetPageLoadingEnd();
      },
    },
    {
      id: 'share',
      classForGTM: 'gtm-match-share-btn',
      isMyMenu: false,
      icon: <ShareIcon fontSize="small" />,
      onClick: handleClickShareBtn,
    },
    {
      id: 'delete',
      classForGTM: 'gtm-match-delete-btn',
      isMyMenu: true,
      icon: <DeleteIcon fontSize="small" sx={{ color: 'error.main' }} />,
      customSx: { color: 'error.main' },
      onClick: (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        setIsShowMatchDeleteDialog(true);
        setAnchorEl(null);
      },
    },
  ];

  return (
    <>
      <IconButton
        className="gtm-match-more-btn"
        size="large"
        sx={{ height: 24, width: 24 }}
        aria-label="more"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setAnchorEl(e.currentTarget);
        }}
      >
        <MoreIcon />
      </IconButton>

      {isNotPc ? (
        <Drawer
          id={
            isMyPage
              ? 'gtm-myMatch-kebab-menu-area'
              : 'gtm-match-kebab-menu-area'
          }
          anchor="bottom"
          open={Boolean(anchorEl)}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onClose={() => setAnchorEl(null)}
          PaperProps={{
            sx: { borderTopLeftRadius: 8, borderTopRightRadius: 8 },
          }}
        >
          <Box sx={{ py: 2 }}>
            {menuList.map(({ id, isMyMenu, icon, customSx, onClick }) => {
              if (!isMyPage && isMyMenu) return null;

              return (
                <MenuItem key={id} onClick={(e) => onClick(e)} sx={customSx}>
                  <ListItemIcon>{icon}</ListItemIcon>
                  {t(`manage.menu.${id}`)}
                </MenuItem>
              );
            })}
          </Box>
        </Drawer>
      ) : (
        <Menu
          id={`menu ${
            isMyPage
              ? 'gtm-myMatch-kebab-menu-area'
              : 'gtm-match-kebab-menu-area'
          }`}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          {menuList.map(
            ({ id, classForGTM, isMyMenu, icon, customSx, onClick }) => {
              if (!isMyPage && isMyMenu) return null;

              return (
                <MenuItem
                  key={id}
                  className={classForGTM}
                  onClick={(e) => onClick(e)}
                  sx={customSx}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  {t(`manage.menu.${id}`)}
                </MenuItem>
              );
            },
          )}
        </Menu>
      )}

      {isMyPage ? (
        <>
          {isEditPopupOpen && matchDetailDataRef.current ? (
            <MatchEditPopup
              open={isEditPopupOpen}
              matchData={matchDetailDataRef.current}
              close={() => setIsEditPopupOpen(false)}
            />
          ) : null}
          {isCopyPopupOpen ? (
            <MatchGeneratePopup
              open={isCopyPopupOpen}
              copyData={matchCopyDataRef.current}
              close={() => setIsCopyPopupOpen(false)}
            />
          ) : null}
          {isPublicPopupOpen ? (
            <DisclosureDialog
              matchId={matchId}
              initDisclosure={disclosure}
              open={isPublicPopupOpen}
              onClose={() => setIsPublicPopupOpen(false)}
            />
          ) : null}

          {isShowMatchDeleteDialog ? (
            <DeleteDialog
              type="MATCH"
              open={isShowMatchDeleteDialog}
              close={() => setIsShowMatchDeleteDialog(false)}
              data={{
                thumbnailUrl: thumbnailImg,
                title: matchListData.title,
                createdAt: matchListData.createdAt,
                views: matchListData.views,
              }}
              confirmDelete={async () => {
                await deleteStoreMatchData(matchId);
                try {
                  await deleteMatch(matchId);
                  await resetQueries(['matchList']);
                  enqueueSnackbar(t('deleteMatchDataDialog.complete'), {
                    variant: 'info',
                  });
                } catch (error) {
                  console.error('Sever match data delete error', error);
                  enqueueSnackbar(t('deleteMatchDataDialog.error'), {
                    variant: 'error',
                  });
                }

                setIsShowMatchDeleteDialog(false);
                await router.replace(router.asPath);
              }}
            />
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default MatchCardMenuButton;
