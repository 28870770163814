import { useCallback, useState } from 'react';

const useLoading = (initState = false) => {
  const [isLoading, setIsLoading] = useState<boolean>(initState);

  const loadingStart = useCallback(() => setIsLoading(true), []);

  const loadingEnd = useCallback(() => setIsLoading(false), []);

  return { isLoading, loadingStart, loadingEnd };
};

export default useLoading;
